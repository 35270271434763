import {utils} from "frontend/utilities";

var cookieConsent = (function(){
  var loadByDefault = true;
  var cookieModal = document.getElementById('cookieModal');

  function loadScripts() {

    //<!--Start of Tawk.to Script-->
    //if (document.body.getAttribute('data-show-chat') == "true") {
    //  utils.loadScript("https://embed.tawk.to/5a5e5f0a4b401e45400c24cc/default", (function(){}),document.head, {charset: "UTF-8", crossorigin: "*"});
    //}
    //<!--End of Tawk.to Script-->

  }

  function removeConsentForm() {
    var element = document.getElementById("cookieNotice");
    element.parentNode.removeChild(element);
  }

  function acceptCookies() {
    utils.setCookie("cookie_consent", true, 365);
    removeConsentForm();
  }

  function declineCookies() {
    utils.setCookie("cookie_consent", false, 365);
    removeConsentForm();

    //if(typeof Tawk_API.hideWidget === "function"){
    //  Tawk_API.hideWidget();
    //}else{
    //  Tawk_API.onLoad = function(){
    //      Tawk_API.hideWidget();
    //  };
    //}


    //load iframe to opt out of adspeed cookies - does not appear to work in Safari but Safari blocks the cookies anyway.
    //var iframe = document.createElement("iframe");
    //iframe.src = "https://g.adspeed.net/ad.php?do=optout&confirmed=1";
    //iframe.style = "display: none;"
    //iframe.title = "AdSpeed Opt Out of Cookies";
    //document.body.append(iframe);

    //sending AJAX request with "withCredentials = true" sets the AdSpeed Opt Out of cookies cookie.
    //This can be seen on the next request to g.adspeed.com in the cookie request header.
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET","https://g.adspeed.net/ad.php?do=optout&confirmed=1",true);
    xhttp.withCredentials = true;
    xhttp.send();

    //blacklisted cookies
    /*
    var blacklisted_cookies = ["TawkConnectionTime", "__tawkuuid", "tawkUUID","ss"];
    var blacklisted_keywords = ["Tawk"];
    var i;
    for (i = 0; i < blacklisted_cookies.length; i++) {
      utils.deleteCookie(blacklisted_cookies[i]);
    }

    var cookies = document.cookie.split(";");

    for (i = 0; i < cookies.length; i++) {
      var name = cookies[i].split("=")[0];
      var x;
      for(x = 0; x < blacklisted_keywords.length; x++){
        if( name.toLowerCase().includes(blacklisted_keywords[x].toLowerCase()) ){
          utils.deleteCookie(name);
        }
      }
    }
    */


  }

  function isMobileDevice() {
    let check = false;
    (function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
      check = true;
    })(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  }

  function createForm(){
    var cookieNotice = document.createElement("div");
    cookieNotice.id = "cookieNotice";

    var content = document.createElement("div");
    content.className = "content";

    var message = document.createElement("div");
    message.innerHTML = "This website uses cookies to ensure you get the best experience. By continuing to use this website, you accept these cookies. You can change your cookies in your browser settings. For more information, see our <a href='/privacy-policy'>cookie policy</a>.";
    message.className = "statement";

    /*
    var policyLink = document.createElement("a");
    policyLink.href = "/privacy-policy";
    policyLink.text = "Cookie Policy";
    */

    var actions = document.createElement("div");
    actions.className = "actions";

    var accept = document.createElement("button");
    accept.id = "accept";
    accept.type = "button";
    accept.textContent = "Accept";
    accept.setAttribute("data-bs-dismiss", "modal");
    accept.setAttribute("aria-label", "Close");
    accept.addEventListener("click", acceptCookies, false);

    var decline = document.createElement("button");
    decline.id = "decline";
    decline.type = "button";
    decline.innerHTML = "Decline";
    decline.setAttribute("data-bs-dismiss", "modal");
    decline.setAttribute("aria-label", "Close");
    decline.addEventListener("click", declineCookies, false);

    actions.appendChild(accept);
    actions.appendChild(decline);
    content.appendChild(message);
    //content.appendChild(policyLink);
    content.appendChild(actions);
    cookieNotice.appendChild(content);

    if(isMobileDevice()) {
      var modal = new bootstrap.Modal(cookieModal);
      cookieModal.querySelector('.modal-body').appendChild(cookieNotice);
      modal.show();
    } else {
      document.body.appendChild(cookieNotice);
    }
  }

  return {
    load: function(){
      if (utils.getCookie("cookie_consent") == "") {
        createForm();
      }
      //loads scripts by default if nothing is set or load the cookie if the consent form is true
      if ((utils.getCookie("cookie_consent") == "" && loadByDefault) || utils.getCookie("cookie_consent") == "true") {
        loadScripts();
      }
    },
    showForm: function(){
      createForm();
    },
    setLoadByDefault: function(value){
      loadByDefault = value;
    }
  }

})();

export {
  cookieConsent
};

//<div id="cookieNotice">
//  <div class="content">
//    <div>This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization.</div>
//    <a href="/privacy-policy">Cookie Policy</a>
//    <div class="actions">
//      <button id="accept" type="button">Accept</button>
//      <button id="decline" type="button">Decline</button>
//    </div>
//  </div>
//</div>

import { Controller } from "stimulus"

export default class extends Controller {
  
  initialize() {
    window.initializeAddressAutocomplete = () => this.initializeAddressAutocomplete()
    
    const googleMapsScript = document.createElement('script')
    googleMapsScript.async = true
    googleMapsScript.defer = true
        
    googleMapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAR39tvSyrjjayEa6kQKXDZtderUtCIe1w&loading=async&libraries=places&callback=initializeAddressAutocomplete'
        
    document.head.appendChild(googleMapsScript)
  }

  initializeAddressAutocomplete(){
    window.dispatchEvent(new Event('address-autocomplete-initialized'))
  }

}

const mobileMenu = (function(){

	function scrollToTop() {
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth'
		});
	}

	function activateMobileSearch() {
		const mobileSearchBlock = document.querySelector('.mobile-search-block');

		if (mobileSearchBlock) {
		  if (mobileSearchBlock.style.display === 'block') {
			mobileSearchBlock.style.display = 'none';
			mobileSearchBlock.classList.add('hidden');
		  } else {
			mobileSearchBlock.style.display = 'block';
			mobileSearchBlock.classList.remove('hidden');

			const input = mobileSearchBlock.querySelector('input');
			if (input) {
			  input.focus();
			}
		  }
		}
	}

	function activateBurgerMenu() {
		const burgerMenuBlock = document.querySelector('.burger-menu-block');

		if (burgerMenuBlock) {
		  if (burgerMenuBlock.style.display === 'block') {
			burgerMenuBlock.style.display = 'none';
			burgerMenuBlock.classList.add('hidden');
		  } else {
			burgerMenuBlock.style.display = 'block';
			burgerMenuBlock.classList.remove('hidden');
		  }
		}
	}

	return {
		init: function(){
			const upside_button = document.getElementById('upside-button');
			const searchButton = document.getElementById('search-button');
			const burgerMenuButton = document.getElementById('burger-menu-button');

			if (upside_button) {
			  upside_button.addEventListener('click', scrollToTop);
			}

			if (searchButton) {
			  searchButton.addEventListener('click', activateMobileSearch);
			}

			if (burgerMenuButton) {
				burgerMenuButton.addEventListener('click', activateBurgerMenu);
			}
		}
	}

})();

export {mobileMenu};

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["subTopicNavLink"]

  connect() {
    console.log("EPIC Content connected")
    this.initObservers();
  }

  initObservers() {
    document.addEventListener("DOMContentLoaded", () => {
      const sections = document.querySelectorAll(".page-section");
      const sidebarLinks = document.querySelectorAll(".chapter-links");
  
      const options = {
        rootMargin: "0px",
        threshold: 0.1,
      };
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              sidebarLinks.forEach((link) => link.classList.remove("active"));
  
              const activeLink = document.querySelector(
                `.chapter-links a[href="#${entry.target.id}"]`
              );
  
              if (activeLink && activeLink.parentElement) {
                activeLink.parentElement.classList.add("active");
              }
            }
          });
        }, options
      );
  
      // Observe all sections
      sections.forEach((section) => observer.observe(section));
    });
  
    document.addEventListener("DOMContentLoaded", () => {
      const sections = document.querySelectorAll(".common-content-bx .cnt-heading");
      const sidebarLinks = document.querySelectorAll(".sub-topic-nav-links");
  
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              sidebarLinks.forEach((link) => link.classList.remove("selected"));
  
              const selectedLink = document.querySelector(
                `a[href="#${entry.target.id}"].sub-topic-nav-links`
              );
  
              if (selectedLink) {
                selectedLink.classList.add("selected");
              }
            }
          });
        },
      );
  
      // Observe all sections
      sections.forEach((section) => observer.observe(section));
    });
  }

  markAsSelected(event) {
    this.subTopicNavLinkTargets.forEach(link => link.classList.remove("selected"));
    event.currentTarget.classList.add("selected");
  }

  toggleAnswer(event) {
    const el = event.currentTarget;
    const index = el.dataset.index;
    this.element.querySelector(`#subtopic-content-${index}`).classList.toggle("active");
    el.querySelector(".arrow-img").classList.toggle("active");
  }
}

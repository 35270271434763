import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import ImageZoom from "js-image-zoom";
import Panzoom from '@panzoom/panzoom'
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/src/assets/sass/glide.core";
import "@glidejs/glide/src/assets/sass/glide.theme";

export default class extends Controller {
  static targets = ["mainCarousel", "thumbnailsCarousel", "zoomContainer", "thumbnailItem"];

  connect() {
    console.log("carousel controller connected")

    this.initializeMbZoom();

    // Add active class to first item
    if (this.thumbnailItemTargets.length > 0) {
      this.thumbnailItemTargets[0].classList.add('active');
    }

    // Initialize the main carousel
    this.mainGlide = new Glide(this.mainCarouselTarget, {
      type: "slider",
      perView: 1,
      keyboard: true,
      focusAt: "center",
      rewind: false,
      autoplay: false,
      dragThreshold: false,
      swipeThreshold: this.isMobileDevice(),
    });

    // Initialize the thumbnails carousel
    this.thumbnailsGlide = new Glide(this.thumbnailsCarouselTarget);

    this.mainGlide.mount();
    this.thumbnailsGlide.mount();

    // Sync thumbnails with the main carousel
    this.mainGlide.on(["run.after"], () => {
      const currentIndex = this.mainGlide.index;
      this.thumbnailsGlide.go(`=${currentIndex}`);
    });
  }

  goTo(event) {
    const index = event.currentTarget.dataset.index;
    this.mainGlide.go(`=${index}`);
    this.thumbnailItemTargets.forEach(function(item){
      item.classList.remove('active');
    });
    event.currentTarget.classList.add('active');

    const productVideo = document.getElementById('product-video');
    if (productVideo) {
      productVideo.pause();
    }
    
    const youtubeVideo = document.getElementById('youtube-video');
    if (youtubeVideo) {
      youtubeVideo.contentWindow.postMessage(
        '{"event":"command","func":"stopVideo","args":""}',
        '*'
      );
    }
  }

  initializeZoom(event) {
    const index = event.currentTarget.dataset.index;
    const zoomContainer = this.zoomContainerTargets[index];
    const imageElement = zoomContainer.querySelector('.main-image');
    const imageWidth = imageElement.naturalWidth;

    if (!this.isMobileDevice() && imageWidth > 500)  {
      if (!zoomContainer.hasAttribute("data-zoomed")) {
        const options = { zoomPosition: 'original', height: 500, width: 500 };
        new ImageZoom(zoomContainer, options);
        zoomContainer.setAttribute("data-zoomed", "true");
      }
    } else {
      const panzoomInstance = Panzoom(imageElement, {
        contain: 'outside',
        panOnlyWhenZoomed: true,
        duration: 0,
        minScale: 1
      });

      let isZoomed = false;

      // Toggle zoom in/out on click for each image
      imageElement.addEventListener("dblclick", () => {
        if (isZoomed) {
          panzoomInstance.zoomOut();
        } else {
          panzoomInstance.zoomIn();
        }
        isZoomed = !isZoomed;
      });
    }
  }

  initializeMbZoom() {
    if (this.isMobileDevice()) {
      this.zoomContainerTargets.forEach((zoomContainer) => {
        var img = zoomContainer.querySelector('.main-image');
        const panzoomInstance = Panzoom(img, {
          contain: 'outside',
          panOnlyWhenZoomed: true,
          duration: 0,
          minScale: 1
        });

        let isZoomed = false;

        // Toggle zoom in/out on click for each image
        img.addEventListener("click", () => {
          if (isZoomed) {
            panzoomInstance.zoomOut();
          } else {
            panzoomInstance.zoomIn();
          }
          isZoomed = !isZoomed;
        });
      });
    }
  }

  isMobileDevice() {
    let check = false;
    check = window.innerWidth <= 768
    return check;
  }
}

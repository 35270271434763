import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [
    'replacement'
  ]
  
  static jsonHeader =   {
    headers: {
      'Content-Type': "application/json"
    }
  }
  
  connect () {
    console.log("api replacement controller connected")
    this.replacementTargets.forEach( t => this.replaceNode(t) )
  }
  
  async replaceNode(target) {
    const apiTarget = this.getApiTargetFromTarget(target)
    const response = await fetch(apiTarget, this.jsonHeader)
    const json = await response.json()

    const replacementKey = this.getReplacementKeyFromTarget(target)
    const replacementText = json[replacementKey]

    console.log(replacementText)
    target.innerHTML = replacementText
  }
  
  getApiTargetFromTarget(target) { return target.dataset.apiTarget }
  getReplacementKeyFromTarget(target) { return target.dataset.replacementKey }
}



import {utils} from "frontend/utilities";

const reviews = (function(){

  function initSearchListeners(){
    //search button submit form
    let submitBtn = document.getElementById("searchSubmitBtn");
    submitBtn.addEventListener("click", function(){
      Rails.fire(document.getElementById("filter-parts"), 'submit');
    }, false);

    //sets the value of a hidden input form field when the user types in a search query on filter-parts form.
    document.addEventListener("keyup", function(e){
      if( e.target.id == "reviewSearchTF" ){
        document.getElementById("searchText").value = e.target.value;
      }
    }, false);

    //checkbox filter submit form event
    document.addEventListener("click", function(e){
      let element = e.target;

      if( element.classList.contains("checkbox") ){

        //disable other checkboxes
        let checkboxes = document.getElementsByClassName("checkbox");
        for(let i = 0; i < checkboxes.lenght; i++){
          if( checkboxes[i] !== element ){
            checkboxes[i].querySelector("input").disabled = true;
          }
        }

        element.dataset.checked = true;
        element.querySelector("input").disabled = false;
        element.querySelector(".content").style.display = "block";

        Rails.fire(document.getElementById("filter-parts"), 'submit');

      }
    }, false);

    //select box submit on change - search filter
    document.addEventListener("change", function(e){

      if( e.target.classList.contains("search-now") ){
        Rails.fire(document.getElementById("filter-parts"), 'submit');
      }

    }, false);

    //removes a search filter and submits a form
  	document.addEventListener("click", function(e){

      if( e.target.classList.contains("remove-filter") ){
        document.getElementById(e.target.dataset.hiddenFieldId).disabled = true;
        Rails.fire(document.getElementById("filter-parts"), 'submit');
      }

    }, false);

    //this event listener is for the circle close/expand button on review search filters
  	document.addEventListener("click", function(e){

      if( e.target.classList.contains("expandable-toggle") ){
        e.target.classList.toggle("close");
        document.querySelector(".expandable-sm").classList.toggle("open");
      }

    }, false);
  }//end initSearchListeners

  return {
    init: function(){
      initSearchListeners();
    }
  }
})();
export { reviews };

var utils = (function() {
  return {
    loadScript: function(url, callback, location, options = {}) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;

      if (options["charset"]) {
        script.charset = options["charset"];
      }

      if (options["crossorigin"]) {
        script.setAttribute('crossorigin', options["crossorigin"]);
      }
      //for chat
      //script.charset='UTF-8';
      //script.setAttribute('crossorigin','*');

      if (script.readyState) { // IE
        script.onreadystatechange = function() {
          if (script.readyState == "loaded" || script.readyState == "complete") {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else { // Firefox, Chrome, Safari
        script.onload = function() {
          callback();
        };
      }

      script.src = url;
      location.appendChild(script);
    },
    getCookie: function(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    deleteCookie: function(cname) {
      document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    setCookie: function(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getDeviceType: function() {
      if (window.innerWidth > 991) {
        //desktop
        return "desktop"
      } else if (window.innerWidth > 767) {
        //tablet
        return "tablet"
      } else {
        //mobile
        return "mobile"
      }

    },

    // standard debounce function to cancel later
    // invocations if made too early
    // cancel part doesn't work?
    debounce: function(func, wait, immediate) {
      var timeout;
      return Object.assign(function() {
        var context = this,
          args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      }, {
        cancel() {
          clearTimeout(timeout);
        }
      });
    },

    validateCheckoutFields: function() {
      var submit = true;
      var element = null;
      var reason = "";

      //loop through basic input fields.
      let inputs = document.querySelectorAll('#editQuoteForm input[required]');
      for(let i = 0; i < inputs.length; i++){

        if( !inputs[i].value.trim().length ){
          if (element === null) element = inputs[i];
          inputs[i].classList.add("red-border");
          submit = false;
          reason = "Your address(es) are missing required fields.";
        }else{
          inputs[i].classList.remove("red-border");
        }

      }

      // if (document.querySelector("input[name='quote[shipping_method_id]']:checked") === null) {
      //   submit = false;
      //   reason += " No shipping method is selected."

      // }

      if (element !== null) element.focus();

      let error = document.getElementById("error");
      if (submit != true) {
        error.className = "red";
        error.textContent = "Error: " + reason;
      } else {
        error.className = "";
        error.textContent = "Processing. Please wait.";
      }
      return submit;
    }
  };
})();

export {
  utils
}

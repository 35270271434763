function updateURLAndPage() {
  const url = new URL(window.location.href);
  const checkboxes = document.querySelectorAll('.form-check-input');

  const selectedFilters = {
    product_type: [],
    category: [],
    brand: []
  };

  checkboxes.forEach(checkbox => {
    const productType = checkbox.getAttribute('data-product-type');
    const category = checkbox.getAttribute('data-category');
    const brand = checkbox.getAttribute('data-brand');
    const isChecked = checkbox.checked;

    if (productType) {
      selectedFilters.product_type.push({ key: productType, value: isChecked });
    }

    if (category) {
      selectedFilters.category.push({ key: category, value: isChecked });
    }

    if (brand) {
      selectedFilters.brand.push({ key: brand, value: isChecked });
    }
  });

  const setFilterParams = (filterName, filterArray) => {
    const selectedValues = filterArray
      .filter(filter => filter.value)
      .map(filter => filter.key)
      .join(',');

    if (selectedValues.length > 0) {
      url.searchParams.set(filterName, selectedValues);
    } else {
      url.searchParams.delete(filterName);
    }
  };

  for (const filterName in selectedFilters) {
    setFilterParams(filterName, selectedFilters[filterName]);
    selectedFilters[filterName].forEach(filter => {
      if (filter.key) localStorage.setItem(filter.key, filter.value);
    });
  }

  history.replaceState(null, '', url);
  window.location.reload();
}

function updatePageContent(url) {
  updateCheckboxes('product-type');
  updateCheckboxes('category');
  updateCheckboxes('brand');
}

function updateCheckboxes(attrName) {
  const attrCheckboxes = document.querySelectorAll(`.form-check-input[data-${attrName}]`);
  attrCheckboxes.forEach(checkbox => {
    const attrValue = checkbox.getAttribute(`data-${attrName}`);
    if (attrValue !== null) {
      const urlCheck = new RegExp([attrValue,attrValue.replace(/\s+/g, '-').toLowerCase()].join('|')).test(window.location.pathname + window.location.search)
      const isChecked = localStorage.getItem(attrValue) === 'true' || urlCheck 
      checkbox.checked = isChecked;
    }
  });
}

function initFilterMenu(){
  const toggleIcons = document.querySelectorAll('.toggle-icon');

  toggleIcons.forEach(icon => {
    icon.addEventListener('click', () => {
      const categoryList = icon.parentNode.nextElementSibling;
      const isOpen = categoryList.classList.contains('open');
      const rotated = icon.classList.contains('rotate');
  
      if (isOpen) {
        categoryList.classList.remove('open');
      } else {
        categoryList.classList.add('open');
      }
  
      const arrowIcon = icon.querySelector('svg');
      
      if (rotated) {
        icon.classList.remove('rotate');
      } else {
        icon.classList.add('rotate');
      }
    });
  });
}

function toogleMobileFilter() {
  const filterMenuButton = document.getElementById('mobile-filters');
  const filterMenu = document.getElementById('filters');
  
  if (filterMenuButton == null) {
    return;
  }

  filterMenuButton.addEventListener('click', function() {
    if (filterMenu.classList.contains('d-block')) {
      filterMenu.classList.remove('d-block');
      filterMenu.classList.add('d-none');
    } else {
      filterMenu.classList.remove('d-none');
      filterMenu.classList.add('d-block');
    }
  }); 
}

function createClearFilterButtons() {
  const activeFiltersBlock = document.getElementById('active-filters-block');
  
  if (!activeFiltersBlock) {
    return;
  }

  const selectedFilters = [];

  for (const key in localStorage) {
    const value = localStorage.getItem(key);
    if (value === 'true') {
      const [attrName, attrValue] = key.split('-');
      selectedFilters.push({ name: attrName});
    }
  }

  for (const filter of selectedFilters) {
    const button = document.createElement('button');
    button.type = 'button';
    button.classList.add('btn', 'btn-outline-secondary', 'ms-4', 'rounded-btn');
    button.innerText = `${filter.name}`;
    activeFiltersBlock.appendChild(button);
  }
}

function redirectToCatalogSearch() {
  localStorage.clear();
  window.location.href = '/catalogsearch/result';
}

function initFrequentlyProducts() {
  const frequentlyProductsBlock = document.getElementById('frequently-bought-products-together');

  if (frequentlyProductsBlock.dataset.productUrlKey) {
    const productUrlKey = frequentlyProductsBlock.dataset.productUrlKey;
    const url = `/products/${productUrlKey}/frequently_purchased`;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target.dataset.loaded != 'true') {
          fetch(url, {
            method: "GET",
            headers: {
              "Accept": "application/javascript",
              "Content-Type": "application/json; charset=utf-8"
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.text(); // Use text since it's a JavaScript response
          })
          .then(script => {
            eval(script); // Evaluate the JavaScript response
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
        }
      });
    });

    observer.observe(frequentlyProductsBlock);
  }
}

function initBrandProducts () {
  const brandProductsBlock = document.getElementById('brand-products');

  if (brandProductsBlock.dataset.productUrlKey) {
    const productUrlKey = brandProductsBlock.dataset.productUrlKey;
    const url = `/products/${productUrlKey}/brand_products`;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target.dataset.loaded != 'true') {
          fetch(url, {
            method: "GET",
            headers: {
              "Accept": "application/javascript",
              "Content-Type": "application/json; charset=utf-8"
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.text(); // Use text since it's a JavaScript response
          })
          .then(script => {
            eval(script); // Evaluate the JavaScript response
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
        }
      });
    });

    observer.observe(brandProductsBlock);
  }
}

function initRecentViewProducts () {
  const brandProductsBlock = document.getElementById('recent-view-products');

  if (brandProductsBlock.dataset.productUrlKey) {
    const productUrlKey = brandProductsBlock.dataset.productUrlKey;
    const url = `/products/${productUrlKey}/recent_view_products`;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.target.dataset.loaded != 'true') {
          fetch(url, {
            method: "GET",
            headers: {
              "Accept": "application/javascript",
              "Content-Type": "application/json; charset=utf-8"
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.text(); // Use text since it's a JavaScript response
          })
          .then(script => {
            eval(script); // Evaluate the JavaScript response
          })
          .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
          });
        }
      });
    });

    observer.observe(brandProductsBlock);
  }
}

const products = (function() {
  function initProductOptions() {
    let productOptions = document.getElementsByClassName("product-options");
    const afterpay_placement = document.getElementsByTagName("afterpay-placement")[0];
    for (let i = 0; i < productOptions.length; i++) {
      productOptions[i].addEventListener("click", function(event) {
        let label = document.querySelector("label[for='" + this.id + "']").innerText;
        let start = label.indexOf("$");
        let price = label.substring(start, label.length);
        afterpay_placement.dataset.amount = price;
        let prices = document.getElementsByClassName("price");
        for (let x = 0; x < prices.length; x++) {
          prices[x].innerText = price;
        }
      }, false);
    }
  }

  return {
    init: function() {
      // initProductOptions();
      // initFilterMenu();
      // toogleMobileFilter();
      // createClearFilterButtons();

      // const checkboxes = document.querySelectorAll('.form-check-input');
      // checkboxes.forEach(checkbox => {
      //   checkbox.addEventListener('click', updateURLAndPage);
      // });
      // window.addEventListener('load', () => {
      //   updatePageContent(new URL(window.location.href));
      // });

      // const clearFiltersButton = document.getElementById('clear-filters-btn');
      // if (clearFiltersButton) {
      //   clearFiltersButton.addEventListener('click', redirectToCatalogSearch);
      // }

      initFrequentlyProducts();
      initBrandProducts();
      initRecentViewProducts();
    }
  };
})();

export { products };

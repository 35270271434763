import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "content"];
  static classes = ["hide"];

  connect(e) {
    this.buttonTarget.dataset.action += " click->toggler#toggle";
  }

  toggle(e) {
    this.contentTarget.classList.toggle(this.hideClass);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['lineOne', 'city', 'country', 'state', 'zipCode']

  initialize() {
    if(this._libraryInitialized()){
      this.initAutocomplete()
    } else {
      window.addEventListener('address-autocomplete-initialized',  this.initAutocomplete)
    }
  }

  initAutocomplete = () => {
    if(this._stopInitialization()) { return }

    this.lineOneTarget.setAttribute('placeholder', '')
    this.autocomplete = new google.maps.places.Autocomplete(this.lineOneTarget, {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["address_components"],
      types: ["address"],
    });
    
    this.autocomplete.addListener("place_changed", this.populateFields);
  }

  populateFields = () => {
    const fields = this._extractPlace()
    
    this.lineOneTarget.value = this._concatenateFields(' ', fields['street_number'], fields['route'])
    this.cityTarget.value = this._concatenateFields(' ',fields['locality'])
   
    this.countryTarget.value = this._concatenateFields(null, fields['country'])
    
    // This triggers some other javascript in another file that is changing the values
    // of the state select when the country select value changes.
    this.countryTarget.dispatchEvent(new Event('change', {bubbles: true}))
   
    this.stateTarget.value = this._concatenateFields(null, fields['administrative_area_level_1'])
    this.zipCodeTarget.value = this._concatenateFields('-', fields['postal_code'], fields['postal_code_suffix'])
    
    // Ensure the checkout js knows about the change
    document.dispatchEvent(new Event('submit-checkout-now-override', { bubbles: true }))
  }

  _stopInitialization(){
    if(window.location.pathname.startsWith('/admin/')) {
      return false
    } 

    // Because of the auto save feature, only initialize if it's a blank form.
    return this.lineOneTarget.value.trim().length > 0
  }

  _libraryInitialized(){
    return typeof google !== 'undefined' && typeof google.maps?.places?.Autocomplete !== 'undefined'
  }

  _extractPlace(){
    const place = this.autocomplete.getPlace()
    let fields = {}

    for (const component of place.address_components) {
      fields[component.types[0]] = component.long_name
    }

    return fields
  }

  _concatenateFields(char, ...fields){
    fields = fields.filter(n => n)

    return fields.join(char)
  }
}

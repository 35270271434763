import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["active", "iconActive"];
  static targets = ["toggle", "toggable", "icon"];

  connect() {
    this.toggleTarget.dataset.action += " click->filters--toggler#perform";
  }

  perform(e) {
    e.preventDefault();

    this.toggableTarget.classList.toggle(this.activeClass);
    this.iconTarget.classList.toggle(this.iconActiveClass);
  }
}
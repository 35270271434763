import Toastify from 'toastify-js'

window.showNotifications = () => {
	console.log("Showing Notifications")
	document.querySelectorAll("#flash_messages > div").forEach(node => {

		let style = {}
		switch (node.dataset.type) {
			case 'error': 
			style.background = "red";
			break
			default: 
			style.background = "#1e477d"
		}

		let duration = 5000

		Toastify({
			text: node.dataset.message,
			duration: duration,
			// destination: "https://github.com/apvarun/toastify-js",
			newWindow: true,
			close: true,
			gravity: "bottom", // `top` or `bottom`
			position: "right", // `left`, `center` or `right`
			stopOnFocus: true, // Prevents dismissing of toast on hover
			style,
			onClick: function(){} // Callback after click
		}).showToast();
	})
}

window.addEventListener('DOMContentLoaded', window.showNotifications)

window.showNotice = (text) => {
	const style = { "background": "#1e477d" }
	Toastify({
		text: text,
		duration: 5000,
		// destination: "https://github.com/apvarun/toastify-js",
		newWindow: true,
		close: true,
		gravity: "bottom", // `top` or `bottom`
		position: "right", // `left`, `center` or `right`
		stopOnFocus: true, // Prevents dismissing of toast on hover
		style,
		onClick: function(){} // Callback after click
	}).showToast();
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "items"];

  connect() {console.log("extended warranty controller connected")}

  updateCartTotal() {
    if (this.hasItemsTarget && this.hasCheckboxTarget) {
      const checkboxElement = this.checkboxTarget;
      var status = checkboxElement.checked ? 'Added' : 'Removed';
      var toFrom = checkboxElement.checked ? 'to' : 'from';
      var items_attributes = {};

      this.itemsTargets.forEach(function(element, index){
        items_attributes[index] = {
          id: element.dataset.itemId,
          name: element.dataset.itemName,
          quantity: element.dataset.itemQuantity
        }
      });

      this.itemsTargets.forEach(function(element, index, elements){
        if(checkboxElement.checked === true && element.dataset.tsdItemId === undefined) {
          items_attributes[index + elements.length] = {
            name: element.dataset.tsdItemName,
            product_id: element.dataset.tsdItemProductId,
            quantity: 1,
            tsd_applicable_item_id: element.dataset.itemId,
            _destroy: !checkboxElement.checked
          }
        } else if (checkboxElement.checked === true && element.dataset.tsdItemId !== undefined) {
          items_attributes[index + elements.length] = {
            id: element.dataset.tsdItemId,
            name: element.dataset.tsdItemName,
            product_id: element.dataset.tsdItemProductId,
            quantity: 1,
            tsd_applicable_item_id: element.dataset.itemId,
            _destroy: !checkboxElement.checked
          }
        } else {
          items_attributes[index + elements.length] = {
            id: element.dataset.tsdItemId,
            _destroy: !checkboxElement.checked
          }
        }
      });

      document.querySelector('.loader').classList.remove('hide');
      fetch('/quotes/create_or_update', {
        method: "POST",
        headers: {
          "Accept": "application/javascript",
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          quote: {
            items_attributes: items_attributes,
            warranty_extended: checkboxElement.checked
          },
          status: status,
          to_from: toFrom,
          updateTsd: true
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // Use text since it's a JavaScript response
      })
      .then(script => {
        eval(script); // Evaluate the JavaScript response
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    }
  }
}

import Rails from "@rails/ujs"
import { Controller } from "stimulus"
// import buildHeaders from "../../lib/json_request_headers"

export default class extends Controller {

  static targets = [

  ]


  updateShippingSelection(event) {
    Rails.fire(document.getElementById("shipping-method-form"), 'submit')
  }

  updateDiscount(event) {
    if (event.target.dataset.previous != event.target.value) Rails.fire(document.getElementById('discount-code-form'), 'submit')
  }
}
const customerReviews = (function(){

	function validateForm(){

		var errMessage = document.getElementById("error-msg");
		if(document.querySelector('input[name="customer_review[rating]"]:checked') === null){
		   errMessage.innerText = "Error: Please select a star rating and try again.";
		   return false;
		}

		var recaptcha = $("#g-recaptcha-response").val();
		if (recaptcha === "") {
			errMessage.innerText = "Error: Please check the reCAPTCHA.";
			return false;
		}
		errMessage.innerText = "";
		return true;
	}


	return {
		init: function(){

			let form = document.getElementById("new_customer_review");
			form.addEventListener("submit", function(e){
				let crSubmit = document.getElementById("cr-submit");
				crSubmit.disabled = true;
				if(validateForm() === false){
					crSubmit.disabled = false;
					e.preventDefault();
				}

			}, false);



		}
	}
})();

export { customerReviews };

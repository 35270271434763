import { Controller } from "stimulus"

export default class extends Controller {
  
  connect() {console.log("cart controller connected")}
  
  handleBlur(event) {
    if (event.target.dataset.current_value != event.target.value) {
      this.submitForm()
    }
  }
  
  handleUpdate() {
    this.submitForm()
  }
  
  updateQuantityValue(event) {
    event.target.dataset.current_value = event.target.value
  }
  
  submitForm() {
    Rails.fire(document.getElementById("editQuoteForm"), 'submit')
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
      'productImage', 
      'productCheckbox', 
      'priceBox', 
      'priceLabel', 
      'submitLabel',
      'productQuantity'
  ]

  connect() {
    console.log("Initiating three in one controller")
  }

  toggleProduct(event) {
    let productId = event.target.dataset.productId
    this.recalculatePrice()
    this.toggleQuantityInputDisabled(productId)
  }

  toggleQuantityInputDisabled(productId) {
    let quantityInput = this.productQuantityTargets.find(item => item.dataset.productId == productId)
    let disabled = quantityInput.disabled
    quantityInput.disabled = !disabled
  }

  recalculatePrice() {
      let total_price = this.productCheckboxTargets.filter( element => element.checked == true )
        .map(element => parseFloat(element.dataset.price))
        .reduce((accumulator, currValue) => accumulator + currValue)
      this.priceBoxTarget.innerText = "$"+total_price.toFixed(2)
  }

}

var adLoader = (function(){
	var device_type = null;
	var loadedAdZones = [];
	var initialLoadComplete = false;

	function getScriptObj(url,callback, zones, allAds) {
	   var script = document.createElement("script");
	   script.type = "text/javascript";
	   if (script.readyState){  // IE
	      script.onreadystatechange = function() {
	         if (script.readyState=="loaded" || script.readyState=="complete"){
	            script.onreadystatechange = null;
							//append loaded ads
							loadedAdZones = loadedAdZones.concat(zones);
	            callback(allAds);
	         } // fi
	      };
	   } else {  // Firefox, Chrome, Safari
	      script.onload = function() {
					//append loaded ads
					loadedAdZones = loadedAdZones.concat(zones);
					callback(allAds);
				};
	   } // fi

	   script.src = url;
	   return script;

	   // You can then append this script object to another node, for example:
	   // document.body.appendChild(script);
	}

	function loadAdScript(zones, allAds){
	  // Note: the parameter to enable asynchronous is "zids" and not "zid"
	  // Change oid to your account ID
	  var script = getScriptObj("//g.adspeed.net/ad.php?do=js&oid=22955&zids=" + zones.join("-") + "&wd=-1&ht=-1&target=_blank",render, zones, allAds);
  	// document.body.appendChild(script);
	}



	function render(allAds){

		for(var i = 0; i < allAds.length; i++){
			var ad = allAds[i];
			if(ad.type === "image"){
				document.getElementById(ad.id).style.paddingBottom = ad[device_type].padding;
			}
			AdSpeed_div(ad.id, ad[device_type].zone);
		}
		//set initial load complete
		if(initialLoadComplete == false){
			initialLoadComplete = true;
			initListeners();
		}
	}

	function initListeners(){
		//only refreshes ads if device type has changed
		var currentDevice = td.utils.getDeviceType();
		window.addEventListener('resize', td.utils.debounce(function(e){
				if(td.utils.getDeviceType() != currentDevice){
					adLoader.load("advertisement responsive");
					currentDevice = td.utils.getDeviceType();
				}
		}, 1000));
	}

	return {
		load: function(elements_class = "advertisement"){
			var adSection = document.getElementById("AdSection");
			if(adSection != null){
				device_type = td.utils.getDeviceType();
				var ads = document.getElementsByClassName(elements_class);
				var allAds = [];
				var adZones = [];

				for(var i = 0; i < ads.length; i++){
					var e = ads.item(i);
					var ad = JSON.parse(e.dataset.ad);

					//if ad can be displayed on this device type
					if(ad[device_type].visible){
						allAds.push(ad);

						//if adzone hasn't already been loaded in a previous request and if it isn't already in the current list to load.
						if(loadedAdZones.indexOf(ad[device_type].zone) === -1 && adZones.indexOf(ad[device_type].zone) === -1){
							adZones.push(ad[device_type].zone);
						}

					}else{
						//hides ads that aren't supposed to be displayed if they already were before page resize
						e.innerHTML = "";
						e.style.cssText = "";
					}
				}
				if(adZones.length > 0){
					console.log(adZones);
					//loads ad script if new zones are available.
					loadAdScript(adZones, allAds);
				}else{
					//go ahead and render ads that don't need to be loaded.
					render(allAds);
				}
			}
		}
	}
})();

export{adLoader};

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "eye", "eyeSlash"];
  static classes = ["hide"];

  connect(e) {
    this.eyeTarget.dataset.action += " click->passwords#toggle";
    this.eyeSlashTarget.dataset.action += " click->passwords#toggle";
  }

  toggle(e) {
    this.inputTarget.type = this.inputTarget.type === "password" ? "text" : "password";
    this.eyeTarget.classList.toggle(this.hideClass);
    this.eyeSlashTarget.classList.toggle(this.hideClass);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'recaptchaToken']

  connect() {
    console.log('recaptcha controller connected');
  }

  submitForm(e) {
    e.preventDefault();

    if(!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity();
      return;
    }

    grecaptcha.ready(() => {
      grecaptcha.execute('6LfbME0qAAAAAN6F3967h1Nt9ENYwuMMxHmlHjcE', {action: 'submit'}).then((token) => {
        this.recaptchaTokenTarget.value = token;

        this.formTarget.submit();
      });
    });
  }
}

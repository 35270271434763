
const quotes = (function() {
	function initializeQuantityButtons() {
		const quantityFields = document.querySelectorAll('.qty-field');
	
		quantityFields.forEach(function(field) {
			const parent = field.closest(".input-group-prepend");
			const incrementBtn = parent.querySelector(".increment-btn");
			const decrementBtn = parent.querySelector(".decrement-btn");
	
			incrementBtn.addEventListener("click", function() {
				field.stepUp();
				field.setAttribute('value', parseInt(field.value) + 1)
				submitCheckoutForm();
			});
	
			decrementBtn.addEventListener("click", function() {
				field.stepDown();
				submitCheckoutForm();
			});
		});
	}

	function initCustomCheckboxes() {
		const checkboxImgs = document.querySelectorAll('.checkbox-img');
	  
		checkboxImgs.forEach(function(checkboxImg) {
		  const checkbox = checkboxImg.previousElementSibling;
	  
		  checkboxImg.addEventListener('click', function(e) {
			e.preventDefault();
	  
			checkbox.checked = !checkbox.checked;
			submitCheckoutForm();
		  });
		});
	  }
	
	  function submitCheckoutForm() {
		var submit = true;
		const inputs = document.querySelectorAll('#editQuoteForm input[required]');
  
		for (let i = 0; i < inputs.length; i++) {
		  if (!inputs[i].value.trim().length) {
			submit = false;
		  }
		}
  
		if (submit) {
		  Rails.fire(document.getElementById("editQuoteForm"), 'submit');
		}

		document.addEventListener("ajax:success", function(event) {
			if (event.target.tagName === "FORM") {
			  //window.location.reload();
			}
		});
	  }

	function initCountryStateChanger(){
		document.addEventListener('change', function (e) {
			
			if ( e.target.classList.contains("country") ) {
				
				let e = event.target;
				let stateField = e.closest('.country-and-state').querySelector('.state');
				let stateContainer = stateField.closest(".form-group");
				let states = e.options[e.selectedIndex].dataset.states.split(",");
				let clss = null;
				let newStateField = null;
				if(states.length == 1 && states[0] == ""){
					
					newStateField = document.createElement("input");
					newStateField.setAttribute("type", "text");
					clss = "form-control state submit-later";
					
				}else{
					
					newStateField = document.createElement("select");
					for(var i = 0; i < states.length; i++){
						var option = document.createElement("option");
						option.text = states[i];
						newStateField.add(option);
					}
					clss = "form-control state submit-now"
					
				}
				
				newStateField.setAttribute('data-address-autocomplete-target', 'state')
				newStateField.setAttribute("required", true);
				newStateField.setAttribute("class", clss);
				newStateField.setAttribute("name", stateField.name);
				newStateField.setAttribute("id", stateField.id)
				stateField.parentNode.removeChild(stateField);
				stateContainer.append(newStateField);
				
			}
			
		}, false);
		
	}
	
	
	function initFormSubmitListeners(){
		//disable form inputs when submitting - these are refreshed completely so there is no need to enable them again.
		document.addEventListener("ajax:send", function(e){
			if( e.target.id === "editQuoteForm"){
				let inputs =  document.querySelectorAll('#editQuoteForm input:not([type=hidden]), #editQuoteForm select:not([type=hidden])')
				for(let i = 0; i < inputs.length; i++){
					inputs[i].disabled = true;
				}
			}
		});
		
		
		//submit-now change
		document.addEventListener("change", function(e)
		{
			if ( e.target.classList.contains("submit-now-checked") ){
				if( e.target.checked ){
					submitCheckoutForm(e);
				}
			}else if( e.target.classList.contains("submit-now") ){
				submitCheckoutForm(e);
			}
		}, false);

		document.addEventListener('submit-checkout-now-override', function(){
			submitCheckoutForm()
		})
		
		//keyup on number fields (cart quantity)
		document.addEventListener("keyup", td.utils.debounce(function(e){
			if( e.target.classList.contains("submit-later-number") ){
				submitCheckoutForm(e);
			}
		}, 300), false);
		
		
		//keyup and change listeners for submit later
		document.addEventListener("keyup", td.utils.debounce(function(e){
			if(e.target.classList.contains("submit-later") && !e.target.disabled){
				submitCheckoutForm(e);
			}
		}, 700));
		
		document.addEventListener("change", td.utils.debounce(function(e){
			if(e.target.classList.contains("submit-later") && !e.target.disabled){
				submitCheckoutForm(e);
			}
		}, 700));
		
		//same as billing checkbox event.
		document.addEventListener("click", function(e){

			
			if( e.target.id === "saBilling" ){
				console.log(`processing ${e.target.checked} click of sabilling`)
				let quoteShippingAddr = document.getElementById("quoteShippingAddress");
				let disabled;
				
				if( e.target.checked ){
					disabled = true;
					quoteShippingAddr.className = "hidden";
				}else{
					disabled = false;
					quoteShippingAddr.className = "";
				}
				
				quoteShippingAddr.disabled = disabled;
				let requiredShipFields = quoteShippingAddr.querySelectorAll(".required");
				for(let i = 0; i < requiredShipFields.length; i++){
					if(disabled){
						requiredShipFields[i].required = false;
					}else{
						requiredShipFields[i].required = true;
					}
				}
				
				submitCheckoutForm(e, true);
				
			}
			
		}, false);
		
		
	}
	
	return {
		init: function(){
			initCountryStateChanger();
			initFormSubmitListeners();
			initCustomCheckboxes();
			initializeQuantityButtons();
		},
		initCartElements: function(){
			initializeQuantityButtons();
			initFormSubmitListeners();
		}
	}
})();

window.initCartElements = quotes.init

export {quotes};

import { Controller } from "stimulus"

export default class extends Controller {
  static classes = ["active", "toggle"];
  static targets = ["toggle", "toggable"];

  connect() {
    this.toggleTarget.dataset.action += " click->filters--mobile-toggler#perform";
  }

  perform(e) {
    e.preventDefault();

    this.toggleTarget.classList.toggle(this.toggleClass);
    this.toggableTarget.classList.toggle(this.activeClass);
  }
}
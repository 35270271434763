/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//var $ = require("jquery")
//global.jQuery = require("jquery")

import { polyfills } from 'global/polyfills'

require("frontend/application")


import {utils} from "frontend/utilities";
import {cookieConsent} from "frontend/cookie_consent";
import {adLoader} from "frontend/loaders/ad_loader";

//chat globals
//var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//global.Tawk_API = Tawk_API;
//global.Tawk_LoadStart = Tawk_LoadStart;

//global td namespace
var td = {};
(function(){
    this.utils = utils;
    this.showCookieConsentForm = cookieConsent.showForm,
    this.adLoader = adLoader;
}).apply(td);
global.td = td;

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()

const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


const globalContext = require.context('../global/controllers', true, /\.js$/)
application.load(definitionsFromContext(globalContext))


import { Controller } from "stimulus"

export default class extends Controller {
  connect() {console.log("brands carousel controller connected")}

  click(event) {
    event.preventDefault()
    const forward = event.currentTarget.dataset.value
    this.scrollCarousel(forward)
  }

  scrollCarousel(forward = 'true') {
    const carouselContainer = document.querySelector("#brands_container")
    const carouselWidth = carouselContainer.clientWidth
    const currentScroll = carouselContainer.scrollLeft
    if (forward === 'true')
      carouselContainer.scroll(currentScroll + carouselWidth, 0)
    else
      carouselContainer.scroll(currentScroll - carouselWidth, 0)
  }

}

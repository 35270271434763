
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {console.log("Walkin belts products carousel controller connected")}

  click(event) {
    event.preventDefault()
    const forward = event.currentTarget.dataset.value
    this.scrollCarousel(forward)
  }

  scrollCarousel(forward = 'true') {
    const productsCarouselContainer = document.querySelector("#top_walking_belts_products_container")
    const productsCarouselWidth = productsCarouselContainer.clientWidth
    const productsCarouselScroll = productsCarouselContainer.scrollLeft

    if (forward === 'true')
      productsCarouselContainer.scroll(productsCarouselScroll + productsCarouselWidth, 0)
    else
      productsCarouselContainer.scroll(productsCarouselScroll - productsCarouselWidth, 0)
    }
  }
  